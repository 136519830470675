.testimonial-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* left align items */
    justify-content: center; /* center align content vertically */
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 30px; 
    margin: 30px; 
    max-width: 400px; 
    height: 200px; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: white;
    text-align: left; /* left align text */
}

.testimonial-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.testimonial-text {
    margin-bottom: 15px;
    font-style: italic; 
    font-size: 20px; /* Increase font size */
}

.testimonial-name {
    font-weight: bold;
    font-size: 22px; /* Increase font size */
}
