/* Navbar.css */
html {
    scroll-padding-top: 70px; /* height of your navbar */
    scroll-behavior: smooth;
}

.navbar {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    height: 60px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px;
}

.navbar-logo {
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 35px;
    margin-left: auto;
    margin-right: auto;
}

.navbar-links li a {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease;
}

.navbar-links li a:hover {
    color: #007BFF; /* Change this to your preferred hover color */
}

.navbar.scrolled {
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.2);
}

.hamburger-menu {
    display: none;
}

@media (max-width: 768px) {

    .hamburger-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        z-index: 101;
        position: absolute;
        left: 1rem;
        top: 1rem;
    }

    .hamburger-menu span {
        width: 2rem;
        height: 0.25rem;
        background: black;
        transition: all 0.3s linear;
    }

    .hamburger-menu.open span:nth-child(1) {
        transform: rotate(45deg) translate(0.35rem, 0.35rem);
    }

    .hamburger-menu.open span:nth-child(2) {
        opacity: 0;
    }

    .hamburger-menu.open span:nth-child(3) {
        transform: rotate(-45deg) translate(0.35rem, -0.35rem);
    }

    .navbar-content {
        flex-direction: column;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background: white;
        padding: 0;
        margin: 0;
        list-style: none;
        transition: transform 0.3s ease-in-out;
        transform: translateY(-100%);
        height: calc(100vh - 60px);
    }

    .navbar-links li {
        padding: 1rem 2rem;
    }

    .navbar-links li a {
        width: 100%;
        display: block;
    }

    .navbar-links.open {
        display: flex;
        transform: translateY(0);
    }
}
