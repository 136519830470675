.about-section {
    padding: 60px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
}

.about-section h2 {
    font-size: 2em;
    font-weight: normal;
}

.about-card {
    display: flex;
    align-items: stretch;
    justify-content: space-between; /* This line ensures maximum space between flex items */
    max-width: 1800px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    padding: 80px;
}

.about-text {
    flex: 1.2;
    margin-right: 40px; /* You can decrease this back to the original value or even remove it */
    align-self: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.about-card p {
    font-size: 1.2em;
    margin-bottom: 35px;
    color: #333;
    line-height: 1.8;
    text-align: left;
}

.about-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 80px; /* You can decrease this back to the original value or even remove it */
    border-radius: 10px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1); /* Adds a shadow to the image */
    transition: box-shadow 0.3s ease-in-out; /* This will make the changes smooth when hovering over the image */
}
.about-image img:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* This will increase the shadow when you hover over the image */
}
.about-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .about-section {
        padding: 20px;
    }
    
    .about-card {
        flex-direction: column;
        padding: 20px;
    }

    .about-text {
        margin-right: 0;
        text-align: center;
    }
    
    .about-image {
        margin: 20px 0;  /* Apply top and bottom margins and remove left and right margins */
        align-items: flex-start;  /* Align the image at the start of the container */
    }

    .about-image img {
        max-width: none;  /* Remove the max-width */
        width: 100%;  /* This will make the image take up the full width of its container */
        height: auto;  /* This will maintain the aspect ratio of the image */
    }
}


