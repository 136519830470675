.quote-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 2000px;
    margin: 2rem auto;
    padding: 3em;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;

}
.quote-form h2 {
    font-size: 2em; /* You can adjust the size as needed */
    font-weight: normal;
}

.input-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 50px;  
}

.input-field {
    display: flex;
    flex-direction: column;
    font-family: 'Nunito', sans-serif;

}

.full-width-input-group {
    align-items: flex-start;
}

.quote-form h2 {
    text-align: center;
    margin-bottom: 2em;  
}

.quote-form label {
    display: block;
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-family: Arial, sans-serif;
    margin-bottom: 5px;
    font-family: 'Nunito', sans-serif;

}


.quote-form span {
    color: red;
}

.quote-form input, .quote-form select, .quote-form textarea {
    width: 600px;
    padding: 1em;  
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 18px;  
    font-family: 'Nunito', sans-serif;

}

.quote-form select, .quote-form textarea {
    margin-bottom: 50px;
}

.quote-form textarea {
    height: 150px;  
    resize: vertical;
    width: 100%;  
}

.quote-form button {
    display: inline-block;
    margin-top: 35px;
    padding: 15px 40px;
    font-size: 1.2em;
    font-weight: 400;
    border: 2px solid #333;
    border-radius: 25px;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    letter-spacing: 1px;
    text-decoration: none;
    /* Align the button left: ; */
    font-family: 'Nunito', sans-serif;

    
}

.quote-form button:hover {
    background-color: #333;
    color: #ffffff;
}

.message {
    text-align: center;
    margin-bottom: 2em;
    padding: 15px;
    border-radius: 5px;
    color: white;
    width: 50%;
    max-width: 600px;
    margin: 20px auto;
    position: relative;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    animation: fadeIn 0.5s, fadeOut 0.5s 4.5s;
    opacity: 0;
    animation-fill-mode: forwards;
    /* left align the button */
    

}

.message.error {
    background-color: #f44336;
}

.message.success {
    background-color: #4caf50;
}

.message::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #4caf50 transparent;
    transform: translate(-50%);
}

.message.error::before {
    border-color: transparent transparent #f44336 transparent;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.full-width-input-group select, .full-width-input-group textarea {
    width: 100%;
    padding: 1em;  
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 18px;
    margin-bottom: 50px;
}
@media (max-width: 768px) {
    .input-group {
      grid-template-columns: 1fr;
    }
    .quote-form input, .quote-form select, .quote-form textarea {
      width: 100%;
    }
    .quote-form {
      padding: 1em;
    }
  }
  